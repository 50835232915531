<!--
 * @Date: 2023-10-16 10:24:03
 * @LastEditTime: 2024-04-16 15:43:04
 * @Description: 1024-飞天大模型1.0-卖点生产
-->
<template>
  <div>
    <Viewheader theme="light" ref="navheader" />
    <div class="big-module">
      <div class="big-module_bg">
        <img src="@/assets/img/home/panel1-bg.gif" alt="big-module" />
      </div>
      <div ref="importExport" class="chat-box">
        <div class="chat-title">
          飞天大模型1.0
          <span
            v-show="chatList.length && processStatus !== 1"
            @click="clearChatList"
            >清除记录</span
          >
        </div>
        <!-- 推荐列表 -->
        <div v-show="!chatList.length" class="chat-recommend">
          <div class="chat-recommend_try"><span>AI</span>尝试以下例子：</div>
          <ul>
            <li v-for="(item, index) in recommendList" :key="index">
              <div class="chat-recommend_title">
                <img :src="item.titleImg" alt="" />{{ item.title }}
              </div>
              <img
                @click="
                  send({
                    desc: item.desc,
                    img: item.img,
                    type: item.type,
                  })
                "
                v-if="item.img"
                :src="item.img"
                alt=""
                class="chat-recommend_img cursor"
              />
              <div
                @click="
                  send({
                    desc: item.desc,
                    img: item.img,
                    type: item.type,
                    carry: 1,
                  })
                "
                class="chat-recommend_desc"
              >
                {{ item.desc }}
              </div>
            </li>
          </ul>
        </div>
        <!-- chat列表 -->
        <div class="chat-list">
          <div v-for="(item, index) in chatList">
            <dl v-if="item.role == 'user'" class="chat-box_import">
              <dt><img :src="item.avatar" /></dt>
              <dd>
                <img v-if="item.img" :src="item.img" alt="" />
                <p>{{ item.content }}</p>
              </dd>
            </dl>

            <dl
              :id="'chatListEl' + index"
              v-if="item.role == 'assistant'"
              class="chat-box_export"
            >
              <div
                v-if="loading && index == chatList.length - 1"
                class="chat-process"
              ></div>
              <template v-else>
                <dt>
                  <div class="chat-recommend_try"><span>AI</span></div>
                </dt>
                <dd>
                  <img v-if="item.img" :src="item.img" alt="" />
                  <template v-if="index == chatList.length - 1">
                    <!-- <div> -->
                    <p v-if="item.content">
                      {{ item.content }}
                      <!-- <span class="virtually-cursor"></span> -->
                    </p>
                    <!-- <p v-else>{{ steamContent }} </p> -->
                    <!-- </div> -->
                  </template>
                  <p v-else-if="item.content">{{ item.content }}</p>
                </dd>
              </template>
            </dl>

            <div v-if="item.role == 'assistant' && item.end" class="action-bar">
              <div class="btn-group">
                <el-tooltip
                  popper-class="btn-group-tooltip"
                  effect="dark"
                  content="赞"
                  placement="top"
                >
                  <span
                    class="iconfont icon-zan"
                    :class="{
                      choose: item.feedback === 1,
                    }"
                    @click="
                      () => {
                        item.feedback = 1;
                      }
                    "
                  ></span>
                </el-tooltip>

                <el-tooltip
                  popper-class="btn-group-tooltip"
                  effect="dark"
                  content="踩"
                  placement="top"
                >
                  <span
                    class="iconfont icon-cai"
                    :class="{
                      choose: item.feedback === 2,
                    }"
                    @click="
                      () => {
                        item.feedback = 2;
                      }
                    "
                  ></span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-bar">
          <div
            class="process-status"
            v-if="processStatus == 1 || processStatus == 2"
            @click="handleProcess(processStatus)"
          >
            <img
              type="hover"
              :src="processStatusList[processStatus].img_hover"
            />
            <img type="default" :src="processStatusList[processStatus].img" />
            <span>{{ processStatusList[processStatus].text }}</span>
          </div>
          <div class="upload-wrap">
            <el-upload
              ref="upload"
              :action="action"
              :fileList="fileList"
              auto-upload:false
              list-type="picture"
              :limit="1"
              :multiple="false"
              :show-file-list="false"
              :on-success="handleUploadChange"
              :on-error="hendleUploadFaild"
              :disabled="processStatus == 1"
              accept="image/jpg,image/png,image/jpeg"
            >
              <div class="upload-img">
                <img
                  type="default"
                  src="@/assets/img/1024/uploadimg.png"
                  alt=""
                /><img
                  type="hover"
                  src="@/assets/img/1024/uploadimg_hover.png"
                  alt=""
                />上传图片
              </div>
            </el-upload>
          </div>
          <textarea
            @keydown="handleKeyCode($event)"
            rows="1"
            ref="autoTextArea"
            class="chat-input"
            @input="adjustTextAreaHeight"
            v-model="chatText"
            placeholder="试试输入商品信息，进行商品卖点生成、标题改写、长描生成、卖点词提炼等"
          ></textarea>
          <img
            @click="send"
            class="chat-send"
            src="@/assets/img/1024/send.png"
            alt="发送"
          />
        </div>

        <!-- 版权协议 -->
        <div class="chat-copyright">
          <p class="copyright-item">
            ——以上输出内容均由“<span>Feitian大模型</span>”生成，如需应用请注意核查。
          </p>

          <p class="copyright-pact">
            你的
            <el-button type="text" @click="jumpToProtocol(1)"
              >《隐私政策》</el-button
            >及<el-button type="text" autofocus @click="jumpToProtocol(3)"
              >《AI服务协议》</el-button
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// import { Loading, Message } from 'element-ui' // 这里我是使用elementUI的组件来给提示
import Viewheader from '../../components/viewheader/viewheader.vue';
import { apiUri } from '../../api/txt';
import axios from 'axios';
export default {
  name: 'sellingPoint',
  components: {
    Viewheader,
  },
  data() {
    return {
      loading: false,
      // 图片上传地址
      action: `${apiUri}/img/imgUpload`,
      // 输入文本
      chatText: '',
      // 上传图片
      chatImg: '',
      // 流式内容
      steamContent: '',
      // 对话列表
      chatList: [],
      userInfo: {},
      fileList: [],
      // 当前请求
      source: null,
      // 生成状态 0 -> 未开始/已完成 视图隐藏；1 -> 生成中；2 -> 重新生成，状态1点击后切换为2；
      processStatus: 0,
      // 生成状态对应列表
      processStatusList: {
        1: {
          text: '停止生成',
          img: require('../../assets/img/1024/stop.png'),
          img_hover: require('../../assets/img/1024/stop_hover.png'),
        },
        2: {
          text: '重新生成',
          img: require('../../assets/img/1024/refresh.png'),
          img_hover: require('../../assets/img/1024/refresh_hover.png'),
        },
      },
      // 推荐列表
      recommendList: [
        {
          title: '卖点生成',
          titleImg: require('../../assets/img/1024/dress.png'),
          desc: `请根据提供的产品标题帮忙写出该产品的5个卖点。
                标题：ELLE加厚90白鸭绒连帽羽绒服女2023冬季新款保暖高端中长款外套`,
        },
        {
          title: '图片描述',
          titleImg: require('../../assets/img/1024/smear_title.png'),
          desc: 'product first image.jpg',
          // img: "https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/panel1-dog.3d6d058e.png",
          img: 'https://img.zcool.cn/community/01517f56f882ad32f875a944470cb6.JPG@3000w_1l_0o_100sh.jpg',
          type: 'img',
        },
      ],
    };
  },
  watch: {
    logStatus: {
      immediate: true,
      handler(val) {
        if (!val) {
          // todo
          this.userInfo = {
            userName: localStorage.getItem('userName') || 'fake name',
            avatar:
              localStorage.getItem('headImgKey') ||
              'https://thirdwx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEL0L0mMwYHVdrSmePALvGEdlqWKINcZrk16xBmrxQobNjCfkZpRr99oyL21rzfIfsUlV0lNcekhZA/132',
          };
        }
        // console.log(this.userInfo);
      },
    },
    chatText(val) {
      console.log(val);
    },
  },
  computed: {
    ...mapGetters(['logStatus']),
  },
  methods: {
    // 键盘回车事件
    handleKeyCode(event) {
      if (event.shiftKey && event.keyCode == 13) {
        console.log(this.chatText);
        this.chatText = this.chatText;
      } else if (event.keyCode == 13) {
        if (!event.metaKey) {
          console.log(event.keyCode);
          event.preventDefault();
          this.send({});
        }
      }
    },
    // textarea响应式高度
    adjustTextAreaHeight() {
      const textarea = this.$refs.autoTextArea;
      textarea.style.height = 'auto'; // 重置高度以便重新计算
      textarea.style.height = `${textarea.scrollHeight - 7.5}px`;
      // console.log(textarea.scrollHeight);

      // 如果内容变少，确保<textarea>的高度不超过初始高度
      const computedStyle = window.getComputedStyle(textarea);
      const minHeight =
        parseInt(computedStyle.getPropertyValue('min-height'), 10) || 0;
      textarea.style.height = `${Math.max(textarea.scrollHeight, minHeight)}px`;
    },
    send({ desc = this.chatText, img = '', type = 'text', carry = 0 }) {
      // 是否正在生成内容
      if (this.loading || this.processStatus == 1) {
        // this.$message({
        //     message: '内容生成中',
        //     type: "warning",
        //     customClass: 'custom-el-message',
        //     // duration: 0,
        //     iconClass: '',
        //     offset: 115
        // });
        return;
      }
      if (!desc && !img) {
        this.$message({
          message: '发送内容不能为空！',
          type: 'warning',
          customClass: 'custom-el-message',
          // duration: 0,
          iconClass: '',
          offset: 115,
        });
        return;
      }

      this.loading = true;
      // console.log("processStatus", this.processStatus);
      if (this.processStatus == 1) return;
      this.processStatus = 0;
      this.chatList.push({
        userName: this.userInfo.userName,
        avatar: this.userInfo.avatar,
        content: desc,
        img,
        role: 'user',
        type,
        carry,
      });
      this.chatText = '';
      console.log(this.chatList);

      this.submit();
    },
    // 提交数据格式化
    arrangeData() {
      const lastIndex = this.chatList.length - 1;
      // 图生文
      if (this.chatList[lastIndex].type === 'img') {
        this.chatImg = this.chatList[lastIndex].img;
        return [
          {
            role: 'user',
            prompt: '根据此产品图片，请详细描述下这张图的内容。中文输出',
          },
        ];
      }
      let temp = [...this.chatList];
      // console.log('temp', temp);

      // 删除机器人无用信息-修复大段文字chat回复公司错误的问题  暂时的
      for (let i = 0; i < temp.length - 1; i++) {
        if (temp[i].carry == 1) {
          temp.splice(i, 1);
          i--;
        }
      }
      // console.log('temp', temp);
      let list =
        temp.map((item, index) => {
          const { role, content, type = 'text', img = '' } = item;
          // 最后一条
          if (index === temp.length - 1) {
            console.log(type, img);
            let res = {
              role: 'user',
              prompt: content,
            };
          }
          let res = {
            role,
          };
          if (role == 'user') {
            res.prompt = content;
          } else if (role == 'assistant') {
            res.content = content;
          }
          return res;
        }) || [];
      // 轮数最多5轮
      return list.slice(-10);
    },
    // 提交生成信息
    submit() {
      this.adjustTextAreaHeight();
      const messages = this.arrangeData();
      const { chatImg: img } = this;
      this.chatImg = '';
      // 用户取消
      const source = axios.CancelToken.source();
      let serverTime = 0;
      // console.log('chatList:', this.chatList);
      const carry = this.chatList[this.chatList.length - 1].carry;
      // console.log('carry', carry);
      this.chatList.push({
        role: 'assistant',
        content: ``,
        carry: carry,
      });
      axios({
        method: 'post',
        url: '/api/text/chatSse',
        data: {
          messages,
          imgUrl: img,
        },
        timeout: 3 * 60 * 1000,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('tokenKey')
            ? localStorage.getItem('tokenKey')
            : '',
        },
        //取消请求
        cancelToken: source.token,
        onDownloadProgress: ({ event }) => {
          const index = this.chatList.length - 1;
          const { content: temp } = this.chatList[index];
          if (temp) {
            this.loading = false;
          }
          this.processStatus = 1;
          const xhr = event.target;
          const { responseText } = xhr;
          const { text, state, timestamp, end } = this.arrangeText(
            responseText,
            serverTime
          );
          serverTime = timestamp;

          try {
            const steamContent = temp + text;
            this.$set(this.chatList, index, {
              content: steamContent,
              role: 'assistant',
              carry,
              end,
              feedback: 0,
            });
            if (end) {
              this.processStatus = 0;
            }
            // }
            const ref = document.querySelector(`#chatListEl${index}`);
            ref?.scrollIntoView(false);
          } catch (error) {
            console.log(error);
          }
        },
      })
        .then((response) => {
          const { data: datas } = response;
          const data =
            typeof datas === 'object' ? datas : this.arrangeResData(datas);
          const { state, message } = data;
          console.log(state);

          if (state == '0x0008') {
            this.$message({
              type: 'error',
              message: message,
              duration: 1500,
              offset: 40,
              onClose: () => {
                this.$refs.navheader.logOn();
              },
            });
          } else if (state && state != '0x0000') {
            this.$message({
              type: 'error',
              message: message,
              duration: 1500,
            });
          } else if (data.state == '0x0010') {
            this.$message({
              type: 'error',
              message: data.message,
              duration: 1000,
            });
          }
          this.loading = false;
          this.processStatus = 0;
        })
        .catch((error) => {
          console.log(error);

          this.loading = false;
          // 判断用户是否手动取消
          if (this.userManual) {
            console.log('请求被用户取消');
          } else {
            // 用户超时取消
            console.log('用户超时取消');

            this.$nextTick(() => {
              this.scrollToPos('chatList');
            });
          }
        });
      this.source = source;
    },
    // 处理收到的数据 按时间戳
    arrangeText(responseText, timestamp = 0) {
      let texts = responseText.split('data:');
      let list = texts.filter((item) => item).map((item) => JSON.parse(item));
      const index = list.findIndex((item) => {
        return item.serverTime == timestamp;
      });
      if (index != -1) {
        list = list.slice(index + 1);
      }
      let end = false;
      const text = list.reduce((prev, cur) => {
        end = cur?.data?.end || false;
        if (cur.state !== '0x0000') return '';
        return prev + (cur?.data?.content || '');
      }, '');

      timestamp = list[list.length - 1]?.serverTime || 0;
      return { timestamp, text, end };
    },

    // 跳转至协议页面
    jumpToProtocol(activeId) {
      window.open(
        `https://aishipgo.com/#/protocol?active=${activeId}`,
        '_blank'
      );
    },

    arrangeResData(responseText) {
      const lastIndex = responseText.lastIndexOf(
        'data:',
        responseText.length - 2
      );
      let chunk = responseText;
      try {
        if (lastIndex !== -1) {
          chunk = responseText.substring(lastIndex).substring('data:'.length);
          chunk = chunk ? JSON.parse(chunk) : {};
          return chunk;
        }
      } catch (err) {
        console.log(err);
        return {};
      }
    },
    // 点击终端请求
    stopRes() {
      if (this.source) {
        this.source.cancel('用户手动取消');
        // 判断用户是否手动取消
        this.userManual = true;
        this.processStatus = 2;
        this.loading = false;
      }
    },
    handleProcess(status) {
      if (status === 1) {
        this.stopRes();
      } else if (status === 2) {
        this.chatList.pop();
        this.steamContent = '';
        this.loading = true;
        // console.log(this.chatList);
        this.submit();
      }
    },
    // 清空聊天记录
    clearChatList() {
      this.chatList = [];
    },
    scrollToPos(ref, x, y = 0) {
      const el = this.$refs[ref];
      if (el) {
        el.scrollTop = el.scrollHeight;
      }
      // this.$refs[ref].scrollTo({
      //   top: 10000,
      //   left: y,
      // })
    },
    handleUploadChange(response) {
      if (response.state === '0x0000') {
        this.send({
          desc: '根据此产品图片，请详细描述下这张图的内容',
          img: response.data,
          type: 'img',
        });
      } else if (response.state === '0x0008') {
        this.$message.error(response.message);
        this.$refs.navheader.logOn();
      }
      this.$refs.upload.clearFiles();
    },
    hendleUploadFaild() {
      console.log('图片上传失败');
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
}
li {
  list-style: none;
}
.cursor {
  cursor: pointer;
}
.big-module {
  position: relative;
  width: 100%;
  /* max-width: 1920px; */
  margin: 0 auto;
  /* padding-top: 10%; */
  margin-top: -80px;
  height: 100vh;
  overflow: hidden;
  padding: 100px 80px 40px 80px;
  box-sizing: border-box;
}
.big-module_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.big-module_bg img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.big-module_bg::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 44%;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(180deg, #100029 0%, rgba(16, 0, 41, 0) 100%);
}
.big-module_bg::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 56%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(0deg, #000, rgba(16, 0, 41, 0));
}

.chat-box {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */
  margin: auto;
  padding: 24px 40px 8px 40px;
  background: linear-gradient(
    124deg,
    rgba(255, 255, 255, 0.95) 17%,
    rgba(255, 255, 255, 0.89) 81%
  );
  /* background: linear-gradient(112deg, #a3a3a3 18%, #7a7a7a 80%); */
  border-radius: 16px;
  color: #000;
  /* margin-top: 100px; */
  font-size: 16px;
  box-sizing: border-box;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.chat-title {
  display: flex;
  font-size: 24px;
  color: #6c38e0;
  justify-content: space-between;
}
.chat-title span {
  color: #000108;
  font-size: 16px;
  cursor: pointer;
}
.chat-title span:hover {
  color: #6c38e0;
}
.chat-recommend {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  padding: 24px;
  margin-top: 24px;
}
.chat-recommend li {
  margin-bottom: 24px;
}
.chat-recommend li:last-child {
  margin-bottom: 0;
}
.chat-recommend_try {
  font-size: 20px;
  color: #000;
  display: flex;
  align-items: center;
  /* margin-bottom: 24px; */
}
.chat-recommend_try span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #fff;
  margin-right: 8px;
  background: #6c38e0;
  border-radius: 50px;
  text-align: center;
}
.chat-recommend_title {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #000108;
}
.chat-recommend_title img {
  width: 20px;
  margin-right: 4px;
}
.chat-recommend_img {
  width: 127px;
  margin-top: 10px;
}
.chat-recommend_desc {
  font-size: 16px;
  color: #3033ea;
  text-decoration: underline;
  cursor: pointer;
  white-space: pre-line;
  margin-top: 8px;
}
.chat-box dt {
  position: relative;
  font-weight: 900;
}
.chat-box dl {
  padding-left: 24px;
}
.chat-box dd {
  /* height: 85px; */
  white-space: pre-line;
  overflow-y: auto;
  margin-left: 8px;
  font-size: 16px;
  line-height: 24px;
}
.chat-box dd img {
  width: 196px;
  display: block;
  margin-bottom: 7px;
  border-radius: 12px;
}
.chat-box dd p {
  padding-top: 5px;
  word-break: break-all;
}
/* .chat-box img {
  width: 178px;
  margin-top: 16px;
} */
.chat-box::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.chat-box_import,
.chat-box_export {
  display: flex;
  align-items: flex-start;
  padding: 24px;
  border-radius: 12px;
}
.chat-box_export {
  background: rgba(255, 255, 255, 0.8);
}
.chat-box_export .chat-process {
  position: relative;
  width: 32px;
  height: 32px;
  background: rgba(108, 56, 224, 1);
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid #6c38e0;
  overflow: hidden;
  background-image: url(../../assets/img/1024/process-path.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 241px 15px;
  animation: processpath infinite linear 3s;
}
@keyframes processpath {
  0% {
    background-position: left center;
  }

  100% {
    background-position: 91.5% center;
  }
}
.chat-box_import dt img {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 50px;
}

.mt40 {
  margin-top: 40px;
}

.chat-list {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 65px;
}

.chat-list > div {
  /* width: 100%; */
  /* display: flex; */
  position: relative;
}

.chat-list .action-bar {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px 0;
}

.action-bar .btn-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.btn-group .iconfont {
  cursor: pointer;
  margin-right: 16px;
  font-weight: bold;
  color: #a4a4a4;
}

.btn-group .iconfont.choose {
  color: #6c38e0;
}

.btn-group .iconfont:hover {
  color: #6c38e0;
}

.chat-bar {
  /* position: absolute; */
  position: relative;
  /* bottom: 36px; */
  display: flex;
  align-items: center;
  /* width: calc(100% - 80px); */
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  padding: 16px 16px 16px 24px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #ffffff;
}

.chat-copyright {
  position: relative;
  width: 100%;
  bottom: 0;
  margin: 8px 0;

  display: flex;
  flex-wrap: wrap;

  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4e5969;
  font-weight: 400;
}

.copyright-item span {
  font-weight: 600;
}

.copyright-pact .el-button {
  margin: unset;
  padding: unset;
  font-size: 14px;
  /* line-height: 18px; */
  color: #165dff;
  font-weight: 500;
}

.upload-wrap {
  margin-right: 24px;
  margin-bottom: auto;
}
.upload-img {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 50px;
  width: 50px;
  justify-content: center;
  font-size: 12px;
  color: rgba(93, 95, 108, 1);
  cursor: pointer;
  padding-top: 4px;
}
.upload-img img {
  width: 24px;
  height: 20px;
  margin-bottom: 5px;
}
.upload-img img[type='hover'] {
  display: none;
}

.upload-img:hover img {
  display: none;
}
.upload-img:hover img[type='hover'] {
  display: block;
}
.upload-img:hover img {
  width: 24px;
  height: 20px;
  margin-bottom: 5px;
}
.upload-img:hover {
  color: #000108;
}

.chat-input {
  position: relative;
  padding-left: 24px;
  flex: 1;
  border: 0;
  font-size: 16px;
  color: rgba(93, 95, 108, 1);
  background: transparent;
  outline: none;
  max-height: 100px;
  resize: none;
  box-sizing: border-box;
  padding-right: 24px;
}
.chat-input::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 40px;
  background: rgba(93, 95, 108, 1);
  left: -24px;
  top: 0;
}

.chat-send {
  margin-left: auto;
  margin-top: auto;
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.process-status {
  display: flex;
  align-items: center;
  position: absolute;
  top: -56px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 6px 14px;
  font-size: 14px;
  color: rgba(0, 1, 8, 1);
  cursor: pointer;
}

.process-status img {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}
.process-status img[type='hover'] {
  display: none;
}
.process-status:hover {
  color: #3033ea;
}
.process-status:hover img[type='default'] {
  display: none;
}
.process-status:hover img[type='hover'] {
  display: block;
}

input::-webkit-input-placeholder {
  /* WebKit browsers，webkit内核浏览器 */
  color: rgba(152, 152, 158, 1);
  font-size: 16px;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(152, 152, 158, 1);
  font-size: 16px;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(152, 152, 158, 1);
  font-size: 16px;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: rgba(152, 152, 158, 1);
  font-size: 16px;
}
</style>

<style>
.custom-el-message {
  width: fit-content;
  min-width: fit-content !important;
  padding: 11px 20px !important;
  background: #000 !important;
  border: 0 !important;
}
.custom-el-message .el-message__content {
  color: #fff !important;
}
.custom-el-message .el-message__icon {
  display: none;
}
.virtually-cursor {
  display: inline-block;
  width: 1.5px;
  height: 16px;
  background: #000108;
  animation: blink 0.6s 15 ease-in;
  margin-left: 5px;
  padding-top: 2px;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 0px; /* 设置滚动条宽度 */
}

.btn-group-tooltip {
  padding: 6px 8px !important;
}
</style>
